import React from "react";
import { Link } from "gatsby";
import icon1 from "../../assets/images/services/service-icon1.png";
import icon2 from "../../assets/images/services/service-icon2.png";
import icon3 from "../../assets/images/services/service-icon3.png";

const RelatedServices = () => {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box ">
              <div className="icon">
                <img src={icon1} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">Digital Marketing</Link> */}
                Digital Marketing
              </h3>
              <p>
                Zestechno has helped many companies across to grow their
                businesses online by providing them with cutting-edge internet
                strategies and solutions.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img src={icon2} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">Inbound Marketing</Link> */}
                Inbound Marketing
              </h3>
              <p>
                Boost visibility, enhance the brand position and generate
                relevant leads through inbound marketing managed campaigns.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img src={icon3} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">App & Web Development</Link> */}
                App & Web Development
              </h3>
              <p>
                Investing time and effort into a responsive website that turns
                your online business in market leader, will detach the great
                competition and increase the probability of exploiting a wider
                marketplace.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img src={icon3} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">App & Web Development</Link> */}
                UI/UX Design
              </h3>
              <p>
                For any custom developed project, a professional UX/UI design is
                the essential stage to make a product successful, especially
                when it comes to intuitive user experience.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img src={icon3} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">App & Web Development</Link> */}
                Branding
              </h3>
              <p>
                Brand architecture is a challenge that comes with success. Say
                you create a new product line, a new profit centre or a new
                service. Better yet, you grow or acquire a new business.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img src={icon3} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">App & Web Development</Link> */}
                Strategy & Consulting
              </h3>
              <p>
                We provide The Best IT Staffing Services, Brand Strategy
                Services, Business Consulting Services, Content Marketing
                Services in Chennai. Gain the Focus and Frameworks that will
                take your business to the Next Level.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img src={icon3} alt="about" />
              </div>
              <h3>
                {/* <Link to="/service-details">App & Web Development</Link> */}
                Publishing Services
              </h3>
              <p>
                We work together with customers brainstorming new ideas for
                initiating projects, its stagewise execution, and ensuring
                on-time delivery to publishers employing innovative strategies
                with a focus on results.
              </p>

              {/* <Link to="/service-details" className="read-more-btn">
                Read More <i className="flaticon-right"></i>
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RelatedServices;
